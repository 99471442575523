import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ComponentList from "~/components/ComponentStatus/ComponentList/ComponentList";
export const _frontmatter = {
  "menuLabel": "Component Status",
  "sortOrder": 3
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Component Status`}</h1>
    <p className="p-large">To help you stay up to date, we have created this table to show you all the components that are currently available and what's planned for the future.</p>
    <p>{`This is a list of all the components the design system has scoped in its library currently, and their status. This page is updated consistently, and gives you an idea of the current workload of the design system team, as well as upcoming components that might be useful to you in your features. `}</p>
    <p>{`If there's a component you'd like to suggest please `}<a parentName="p" {...{
        "href": "/help-feedback"
      }}>{`reach out`}</a>{`.`}</p>
    <ComponentList mdxType="ComponentList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      